document.addEventListener('DOMContentLoaded', function(){

    $(function() {
        // Programa de Conferencias
        $('.programa-evento .info-curso:first').show();
        $('.menu-programa a:first').addClass('activo');
    
        $('.menu-programa a').on('click', function() {
            $('.menu-programa a').removeClass('activo');
            $(this).addClass('activo');
            $('.ocultar').hide();
            var enlace = $(this).attr('href');
            $(enlace).fadeIn(1000);
            return false;
        });

    });
    
    darkMode();
    categorias();
   /*  navegacionFija(); */

    
});

/* function navegacionFija () {
    const barra = document.querySelector('.header');
    const scroll = document.querySelector('.scroll');

    window.addEventListener('scroll', function() {

        if(scroll.getBoundingClientRect().top <= 0 ){
            barra.classList.add('fijo');
        } else {
            barra.classList.remove('fijo');
        }
    });
} */

function categorias(){

    /* TRADUCTOR */

    var idioma = $("#idioma").val();
    var url = $("#ruta").val();

    $.ajax({
        url: url+"idiomas.json",
        method: "GET",
        dataType: "json",
        success: function(respuesta){

            function traducir(texto){
                if(idioma == "es"){
                    return texto;
                } else {
                    return respuesta[texto][0].en;
                }
            }

        



            /* IDENTIFICAR LA PANTALLA */

            if(window.matchMedia("(min-width:1200px)").matches) {

                $(document).on("mouseover", ".listaCategorias li", function(){
                    var enlace = $(this).attr("idCategoria");
                    
                    verSubcategorias(enlace);
                })

            } else {
                $(document).on("click", ".listaCategorias li", function(){
                    var enlace = $(this).attr("idCategoria");
                    
                    verSubcategorias(enlace);
                })
            }

            /* CAMBIOS EN LA VENTANA MODAL DE CATEGORIAS */

            function verSubcategorias(enlace) {

                /* CAMBIOS EN SUBCATEGORIAS */

                var datosSubcategorias = new FormData();

                datosSubcategorias.append("idCategoria", enlace);
                datosSubcategorias.append("item", "id_categoria");
                datosSubcategorias.append("tabla", "subcategorias");

                $.ajax({
                    url: url+"ajax/CategoriasAjax.php",
                    method: "POST",
                    data: datosSubcategorias,
                    cache: false,
                    contentType: false,
                    processData: false,
                    dataType: "json",
                    success: function(respuesta){

                        if(window.matchMedia("(max-width:575px)").matches) {
                            $(".listaCategorias").hide();

                            $(".listaSubcategorias").parent().removeClass("d-none");
            
                            $(".listaSubcategorias").parent().show();
            
                            $(".listaSubcategorias").parent().append(
            
                                '<button class="btn btn-default regresarCategorias">'+traducir("Volver")+'</button>'
                                
                            )
            
                            $(".regresarCategorias").click(function(){
            
                                $(this).remove();
                                $(".listaCategorias").show();
            
                                $(".listaSubcategorias").parent().hide();
                            });
                        }

                        $(".listaSubcategorias").html("")
                        
                        for(var i = 0; i < respuesta.length; i++){
                            $(".listaSubcategorias").append(
                                '<a class="text-secondary" href="'+url+idioma+'/'+respuesta[i]["ruta"]+'">'+

                                '<li class="small my-2">'+traducir(respuesta[i]["subcategoria"])+'</li>'+

                            '</a>'
                            )
                        }
                    }
                })

                /* CAMBIOS EN CATEGORIAS */

                var datosCategorias = new FormData();

                datosCategorias.append("idCategoria", enlace);
                datosCategorias.append("item", "id");
                datosCategorias.append("tabla", "categorias");

                $.ajax({
                    url: url+"ajax/CategoriasAjax.php",
                    method: "POST",
                    data: datosCategorias,
                    cache: false,
                    contentType: false,
                    processData: false,
                    dataType: "json",
                    success: function(respuesta){

                        $(".tituloCategoria").html(traducir(respuesta[0]["categoria"]));
                        $(".desCategoria").html(traducir(respuesta[0]["descripcion"]));
                        $(".imgCategoria").attr("src", url+respuesta[0]["imgOferta"]);
                        $(".verCategoria").attr("href", url+idioma+'/'+respuesta[0]["ruta"]);
                    }
                })
            }
        }
    })

}

function darkMode() {
   /*  const prefiereDarkMode = window.matchMedia('(prefers-color-scheme: dark)'); */

    /* console.log(prefiereDarkMode.matches); */
    /* if (prefiereDarkMode.matches) {
        document.body.classList.add('dark-mode');
    } else {
        document.body.classList.remove('dark-mode');
    }
  
    prefiereDarkMode.addEventListener('change', function() {
        if (prefiereDarkMode.matches) {
            document.body.classList.add('dark-mode');
        } else {
            document.body.classList.remove('dark-mode');
        }
    }); */
  
    const botonDarkMode = document.querySelector('.dark-mode-boton');
  
    botonDarkMode.addEventListener('click', function() {
        document.body.classList.toggle('dark-mode');
    });
}



